import React from "react";
import { FormattedMessage, FormattedDate } from "gatsby-plugin-intl";
import dayjs from "dayjs";

interface Props {
  prioritisation: string;
  sport: string;
  startDate: Date;
  endDate: Date;
}

const CaseStudyOverviewTable = (props: Props) => {
  const { prioritisation, sport, startDate, endDate } = props;

  let duration;

  if (!!startDate && !!endDate) {
    const startDateDayJS = dayjs(startDate);
    const endDateDayJs = dayjs(endDate);
    duration = endDateDayJs.diff(startDateDayJS, "week");
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <th scope="row">
            <FormattedMessage
              id="case_study.prioritisation"
              defaultMessage="Prioritisation"
            />
          </th>
          <td>
            <span className="badge bg-primary">
              <FormattedMessage
                id={`prioritisation.${prioritisation.toLowerCase()}`}
                defaultMessage={prioritisation}
              />
            </span>
          </td>
        </tr>
        <tr>
          <th scope="row">
            {" "}
            <FormattedMessage id="case_study.sport" defaultMessage="Sport" />
          </th>
          <td>
            <span className="badge bg-secondary">
              <FormattedMessage
                id={`sport.${sport.toLowerCase()}`}
                defaultMessage={sport}
              />
            </span>
          </td>
        </tr>
        <tr>
          <th scope="row">
            <FormattedMessage id="case_study.dates" defaultMessage="Dates" />
          </th>
          <td colSpan={2}>
            {" "}
            <FormattedDate
              value={new Date(startDate)}
              year="numeric"
              month="short"
              day="2-digit"
            />
            &ndash;
            <FormattedDate
              value={new Date(endDate)}
              year="numeric"
              month="short"
              day="2-digit"
            />
          </td>
        </tr>
        {!!duration && (
          <tr>
            <th scope="row">Duration</th>
            <td colSpan={2}>
              {" "}
              <FormattedMessage
                id="projects.duration_in_weeks"
                defaultMessage="{duration} weeks"
                values={{
                  duration: duration,
                }}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CaseStudyOverviewTable;
