import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";
import { micromark } from "micromark";

import CaseStudyOverviewTable from "../components/case-study-overview-table";
import Layout from "../components/layout";

const CaseStudy = (props) => {
  const {
    title,
    sport,
    prioritisation,
    insight,
    challenge,
    impact,
    learn,
    startDate,
    endDate,
    thumbnail,
  } = props.pageContext.data[props.pageContext.language].frontmatter;

  const insightHTML = !!insight ? micromark(insight) : "";
  const challengeHTML = !!challenge ? micromark(challenge) : "";
  const impactHTML = !!impact ? micromark(impact) : "";
  const learnHTML = !!learn ? micromark(learn) : "";

  return (
    <Layout>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/case-studies"}>
              {" "}
              <FormattedMessage
                id="case_study.back_to_case_studies"
                defaultMessage="Back to all Case Studies"
              />
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {" "}
            <FormattedMessage
              id="case_study.case_study_breadcrumb"
              defaultMessage="Case Study: {caseStudyTitle}"
              values={{ caseStudyTitle: title }}
            />
          </li>
        </ol>
      </nav>
      <article className="row">
        <div className="col-12">
          {" "}
          <h1 className="block-title">{title}</h1>
          <aside className="card bg-light rounded-0 mt-4 skew">
            <div className="card-header bg-secondary text-white rounded-0 case-study-overview__header">
              <h2>
                <FormattedMessage
                  id="case_study.overview"
                  defaultMessage="Case Study Overview"
                />
              </h2>
            </div>
            <div className="card-body">
              <CaseStudyOverviewTable
                prioritisation={prioritisation}
                sport={sport}
                startDate={startDate}
                endDate={endDate}
              ></CaseStudyOverviewTable>
            </div>
          </aside>
          {!!thumbnail && (
            <img
              className="img-fluid float-end mt-5"
              width="500px"
              src={thumbnail}
              alt=""
            />
          )}
          <h3 className="anchor-title mt-5">
            {" "}
            <FormattedMessage
              id="case_study.the_insight"
              defaultMessage="The Insight"
            />
          </h3>
          <div dangerouslySetInnerHTML={{ __html: insightHTML }}></div>
          <h3 className="anchor-title mt-5">
            <FormattedMessage
              id="case_study.the_challenge"
              defaultMessage="The Challenge"
            />
          </h3>
          <div dangerouslySetInnerHTML={{ __html: challengeHTML }}></div>
          <h3 className="anchor-title mt-5">
            <FormattedMessage
              id="case_study.the_impact"
              defaultMessage="The Impact"
            />
          </h3>
          <div dangerouslySetInnerHTML={{ __html: impactHTML }}></div>
          <h3 className="anchor-title mt-5">
            <FormattedMessage
              id="case_study.what_did_we_learn"
              defaultMessage="What did we learn?"
            />
          </h3>
          <div dangerouslySetInnerHTML={{ __html: learnHTML }}></div>
        </div>
      </article>
    </Layout>
  );
};

export default CaseStudy;
